import appApi from '../../../../src/modules/appApi';

class UserEmail {
  getAllaAddedEmailCampaign = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/email_campaign_user/email_campaign_id/' + id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, 'error occurred while getting Users');
      });
  };
  getEmailHIstory = (id,callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/campaign_email_history/${id}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, 'Error occurred while updating user ');
      });
  };
  getAddUserEmail = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: `api/contacts/not_in_email_campaign/` + id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, 'Error occurred while updating user ');
      });
  };

  addUserEmail = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: `/api/email_campaign_user/add`,
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, 'Error Occurred While Adding User ');
      });
  };
}
export default new UserEmail();
