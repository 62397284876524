import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useState } from "react";
import UserList from "./UserList";
import SendEmail from "./SendEmail";


function EmailCampaignDetails({item}) {
  

    const [value, setValue] = useState('1');
    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
      };
      
    return (
        <>
             <Box sx={{ width: '100%', typography: 'body1' }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                        <Tab label="User List" value="1" />
                        <Tab label="Send/Sent Email" value="2" />
                      </TabList>
                    </Box>
                    <TabPanel value="1"> <UserList item={item}/> </TabPanel>
                    <TabPanel value="2"> <SendEmail c_id={item.id}/> </TabPanel>
                  </TabContext>
                </Box>
        </>
    )
}
export default EmailCampaignDetails;